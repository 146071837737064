<template>
  <div
    class="sidebar-dark side-scroll page-header-fixed page-header-glass main-content-boxed"
    id="page-container"
  >
    <nav id="sidebar">
      <div class="sidebar-content">
        <div class="content-header justify-content-lg-center bg-black-10">
          <div>
            <span class="smini-visible fw-bold tracking-wide fs-lg">
              o<span class="text-primary">t</span>
            </span>
            <router-link class="link-fx fw-bold tracking-wide mx-auto" to="/">
              <span class="smini-hidden">
                <i class="fa fa-fire text-primary"></i>
                <span class="fs-4 text-dual">{{ config.webName }}</span>
                <span class="fs-4 text-primary">{{ config.webName }}</span>
              </span>
            </router-link>
          </div>
          <div>
            <button
              class="btn btn-sm btn-alt-danger d-lg-none"
              data-action="sidebar_close"
              data-toggle="layout"
              type="button"
            >
              <i class="fa fa-fw fa-times"></i>
            </button>
          </div>
        </div>
        <div class="js-sidebar-scroll">
          <div class="content-side content-side-full">
            <ul class="nav-main">
              <li class="nav-main-item">
                <router-link class="nav-main-link active" to="/">
                  <i class="nav-main-link-icon fa fa-home"></i>
                  <span class="nav-main-link-name">首页</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <header id="page-header">
      <div class="content-header">
        <div class="space-x-1">
          <a class="link-fx fw-bold" href="/">
            <i class="fa fa-rocket text-primary"></i>
            <span class="fs-4 text-dual">宥马</span>
            <span class="fs-4 text-primary">Tool</span>
          </a>
        </div>
        <div class="d-flex align-items-center space-x-2">
          <ul
            class="nav-main nav-main-horizontal nav-main-hover d-none d-lg-block"
          >
            <li class="nav-main-item">
              <a class="nav-main-link active" href="#home">
                <i class="nav-main-link-icon fa fa-home"></i>
                <span class="nav-main-link-name">首页</span>
              </a>
            </li>
          </ul>
          <div class="dropdown d-inline-block">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              class="btn btn-sm btn-alt-secondary"
              data-bs-auto-close="outside"
              data-bs-toggle="dropdown"
              id="page-header-themes-dropdown"
              type="button"
            >
              <i class="fa fa-paint-brush"></i>
            </button>
            <div
              aria-labelledby="page-header-themes-dropdown"
              class="dropdown-menu dropdown-menu-end"
            >
              <h6 class="dropdown-header text-center">界面颜色</h6>
              <div class="row g-0 text-center">
                <div class="col-4 mb-1">
                  <a
                    class="text-default"
                    data-theme="default"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-4 mb-1">
                  <a
                    class="text-elegance"
                    data-theme="/static/css/themes/elegance.min-5.0.css"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-4 mb-1">
                  <a
                    class="text-pulse"
                    data-theme="/static/css/themes/pulse.min-5.0.css"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-4 mb-1">
                  <a
                    class="text-flat"
                    data-theme="/static/css/themes/flat.min-5.0.css"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-4 mb-1">
                  <a
                    class="text-corporate"
                    data-theme="/static/css/themes/corporate.min-5.0.css"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-4 mb-1">
                  <a
                    class="text-earth"
                    data-theme="/static/css/themes/earth.min-5.0.css"
                    data-toggle="theme"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overlay-header bg-primary" id="page-header-loader">
        <div class="content-header">
          <div class="w-100 text-center">
            <i class="far fa-sun fa-spin text-white"></i>
          </div>
        </div>
      </div>
    </header>
    <main id="main-container">
      <div
        class="bg-body-extra-light overflow-hidden bg-image"
        id="#home"
        style="background-image: url('/static/media/photos/background.jpg')"
      >
        <div class="hero">
          <div class="hero-inner">
            <div class="content content-full text-center">
              <h1 class="display-3 fw-bold text-white mb-2">
                {{ config.webName }}
              </h1>
              <h4 class="fw-medium text-white-50 mb-5">
                {{ config.webName }}你的私人助手
              </h4>
              <router-link
                class="btn btn-lg btn-alt-info fw-semibold me-1 mb-2 fs-sm"
                to="login"
              >
                <i class="si si-login opacity-50 me-1"></i> 登 录
              </router-link>
              <router-link
                class="btn btn-lg btn-primary fw-semibold mb-2 fs-sm"
                to="register"
              >
                <i class="si si-energy opacity-50 me-1"></i> 注 册
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-body-light" id="#features">
        <div class="content content-full">
          <div class="py-5 text-center">
            <h3 class="fw-bold mb-2">特色</h3>
            <div class="position-relative">
              <div class="content content-full">
                <div class="row g-0 justify-content-center text-center py-5">
                  <div class="col-xl-4">
                    <div class="w-100 py-4">
                      <div class="rounded p-1 mb-4">
                        <div class="rounded p-4">
                          <i class="fa fa-cubes fa-2x text-corporate"></i>
                        </div>
                      </div>
                      <h3 class="h4 fw-bold mb-1">高效云端</h3>
                      <p class="fw-medium text-muted mb-0 mt-1">
                        同步更新项目最新接口<br />
                        彻底杜绝宥马检测<br />
                        高度模拟真人执行跑步
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="w-100 py-4">
                      <div class="rounded p-1 mb-4">
                        <div class="rounded p-4">
                          <i class="fa fa-code fa-2x text-pulse"></i>
                        </div>
                      </div>
                      <h3 class="h4 fw-bold mb-1">原创程序</h3>
                      <p class="fw-medium text-muted mb-0">
                        用户界面简单易懂人性化<br />
                        自研开发程序 稳定更新 <br />
                        实时反馈程序问题 快速修复
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="w-100 py-4">
                      <div class="rounded p-1 mb-4">
                        <div class="rounded p-4">
                          <i class="fa fa-rocket fa-2x text-elegance"></i>
                        </div>
                      </div>
                      <h3 class="h4 fw-bold mb-1">分布式架构</h3>
                      <p class="fw-medium text-muted mb-0">
                        分布式系统架构 分布式监控系统<br />
                        24小时不间断 完美放手托管<br />
                        故障不影响整体运行 确保24小时稳定
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-image"
        id="#about"
        style="background-image: url('/static/media/photos/photo26@2x.jpg')"
      >
        <div class="bg-black-75">
          <div class="content content-top text-center">
            <div class="py-7">
              <h1 class="fw-bold text-white mb-2">
                运营 {{ config.dayCount }} 天
              </h1>
              <h2 class="h4 fw-normal text-white-75">
                我们正在为 {{ config.userCount }} 位 用户提供服务
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-body-light">
        <div class="content content-full">
          <div class="py-5 text-center">
            <h3 class="fw-bold mb-2">坚持你的梦想</h3>
            <h4 class="fw-normal text-muted mb-4">
              永远相信美好的事情 即将发生
            </h4>
            <router-link class="btn btn-alt-primary" to="/login"
              >加入我们</router-link
            >
          </div>
        </div>
      </div>
    </main>

    <footer class="bg-body-extra-light" id="page-footer">
      <div class="content content-full">
        <div class="row g-0 fs-sm border-top pt-3">
          <div class="col-sm-6 order-sm-2 py-1 text-center text-sm-end">
            &copy; <span data-toggle="year-copy"></span>
            <a class="fw-semibold" href="#" target="_blank">{{
              config.webName
            }}</a>
            <!--                                Crafted with <i class="fa fa-heart text-danger"></i> by <a class="fw-semibold" href="#" target="_blank">God-Team</a>-->
          </div>
          <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-start">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="text-muted fw-semibold"
              >皖ICP备2021015069号</a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import store from "@/store";
import { getWebInfo } from "@/api/webInfo";

export default {
  name: "Index",

  data() {
    const defaultForm = {
      webName: "",
      userCount: 0,
      taskCount: 0,
      jobCount: 0,
      dayCount: 0,
      notice: [],
    };
    return {
      config: { ...defaultForm },
    };
  },

  mounted() {
    let script = document.createElement("script");
    script.src = "/static/js/codebase.app.min-5.1.js";
    document.body.appendChild(script);
    getWebInfo().then((res) => {
      this.config = res;
      store.commit("setWebName");
    });
  },

  methods: {},
};
</script>

<style scoped></style>
